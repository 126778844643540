/**
 * Обертка над уплоадером, немного приведенная в чувства (надо рефакторить и расширять)
 *
 * для взаимодейсвия с этим всем использовать FileUploaderApplication
 *
 * ждет на вход контейнер в котором будет искать
 * input.js-files - собственно инпут в который будут сложены данные про файлы (его надо отправлять на сервак при отправке формы)
 * .js-file_uploader__file_percent - для отображения процента загрузки файла
 * .js-template-file - контейнер темплейта для строчки обозначающей файл
 *  в ней может быть разная фигня для отображения файла
 *  сейчас там ищется .js-file_uploader__file_remove как кнопка удаления и .js-file_uploader__file_name для записи имени файла
 *
 * TODO: сделать все это конфигурябельным через параметры
 * INFO: не тестилось ещё все это
 *
 * @param container
 * @constructor
 */
function TurboUploader(container) {
    if (container instanceof Element) {
        this.container = container;
        this.$container = $(container);
    } else {
        throw 'Укажите container';
    }

    if (!(this.input = this.container.querySelector('input.js-files'))) {
        throw 'В container должен быть input для хранения информации о загруженных файлах';
    }
    if (!(this.button = this.container.querySelector('input.js-file-button'))) {
        throw 'В container должен быть input для добавления файлов';
    }

    if (!(this.template = this.container.querySelector('.js-template-file'))) {
        throw 'Нужен template для отображения списка файлов';
    }

    var self = this;

    $(this.button)
        .fileupload({
            dataType: 'json',
            maxChunkSize: 5000000, // 5 MB
            url: "/file-upload/",
            paramName: "files[]",
            add: function (e, data) {
                self.add(e, data);
            }
        })
        .bind('fileuploadprogress', function (e, data) {
            self.progress(e, data);
        })
        .bind('fileuploadfail', function (e, data) {
            self.fail(e, data);
        })
        .bind('fileuploadchunkfail', function (e, data) {
            self.chunkFail(e, data);
        })
        .bind('fileuploaddone', function (e, data) {
            self.done(e, data);
        });
}

TurboUploader.prototype.progress = function (e, data) {
    var progress = parseInt(data.loaded / data.total * 100, 10);

    this.$container
        .find(".js-file_uploader__file[data-identify=" + data.files[0].identify + "]")
        .find(".js-file_uploader__file_percent")
        .text("(загружено - " + progress + "%)");
};

TurboUploader.prototype.fail = function (e, data) {
    console.log("fail");
};

TurboUploader.prototype.chunkFail = function (e, data) {
    console.log("chunk fail");
};

TurboUploader.prototype.done = function (e, data) {
    var id = data.files[0].identify,
        $item = this.$container
            .find(".js-file_uploader__file[data-identify=" + id + "]");

    if (data.result.files[0].hasOwnProperty("error")) {
        alert("Ошибка при загрузке файла " + data.result.files[0].name);
        $item.remove();

    } else {
        $item
            .attr("data-file_path", data.result.files[0].url);

        $item
            .find(".js-file_uploader__file_remove")
            .text("удалить");

        this.updateInputData();
    }
};

TurboUploader.prototype.updateInputData = function () {
    var files = this.container.querySelectorAll('.js-file_uploader__file');
    this.input.value = '';
    var newValue = [];
    files.forEach(function (item) {
        newValue.push({
            identify: item.dataset.identify,
            path: item.dataset.file_path
        })
    });
    this.input.value = JSON.stringify(newValue);
};

TurboUploader.prototype.add = function (e, data) {
    var identify = null,
        $item = null,
        self = this;

    $.ajax({
        async: false,
        type: 'POST',
        dataType: 'json',
        url: '/file-upload/get-identify/',
        success: function (response) {
            if (response.success) {
                identify = response.identify;

                $item = $(self.template).clone();
                $item
                    .removeAttr("id")
                    .addClass("js-file_uploader__file")
                    .insertAfter(self.template)
                    .attr("data-identify", identify)
                    .show();

                $item.find(".js-file_uploader__file_name").text(data.files[0].name);

                data.files[0].identify = identify;
            } else {
                alert(response.message);
            }
        }
    });


    if (!identify) {
        data.abort();
        return false;
    }


    data.formData = {identify: identify};

    if (e.isDefaultPrevented()) {
        return false;
    }
    if (data.autoUpload || data.autoUpload !== false) {
        data.process().done(function () {
            var jqXHR = data.submit();

            $item.find(".js-file_uploader__file_remove").on("click", function () {
                $item.remove();
                jqXHR.abort();
                self.updateInputData();
            });
        });
    }
};

TurboUploader.prototype.reset = function () {
    this.$container.find('.js-file_uploader__file').map(function (i, item) {
        $(item).remove();
    });

    this.updateInputData();
};
